<template>

  <div class="max-w-md mx-4 md:mx-auto md:max-w-6xl justify-center">
      <slot/>
  </div>
</template>


<script setup>
 
 
  </script>